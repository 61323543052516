@import './src/scss/variables';
@import './src/scss/app';

.widget__tab {
	&-list {
		display: flex;
		justify-content: center;
		gap: 1rem;
		padding-bottom: 1rem;

		li {
			a {
				@include text-decoration;
		  }

		  &.active a {
		    cursor: default;
		    opacity: 1 !important;
		    text-decoration: none;
		  }
		}
	}
}